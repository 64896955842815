import React, { useEffect } from "react";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";
import { useRecoilValue } from "recoil";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { setUserInfoToChannelIO } from "@sellernote/_shared/src/services/chat/channelIO";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import useSwitchLatestUsedTeamAccount from "@sellernote/shipda-kr/src/hooks/common/useSwitchLatestUsedTeamAccount";
import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";
import { TEAM_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/team";

import PopUpOnShipda from "../popUp/PopUpOnShipda";
import FloatingChatButton from "./FloatingChatButton";
import Footer from "./Footer";
import Header from "./Header";
import InvitationModal from "./InvitationModal";
import MyPageHeader from "./MyPageHeader";
import useAccessedByEmailInvitationModal from "./useAccessedByEmailInvitationModal";
import useCheckInvitationTeamListWhenTokenRefreshed from "./useCheckInvitationTeamListWhenTokenRefreshed";
import useSetGTMByUserInfo from "./useSetGTMByUserInfo";
import Styled from "./index.styles";

export type LayoutSize = "full";

export interface LayoutProps {
  children: React.ReactNode;
  size?: LayoutSize;
  backgroundColor?: string;
  hasBottomNavigator?: boolean;
  /**
   * calc 사용을 위해 string 으로 정의. % 나 px 등 단위를 직접 입력해야 함.
   */
  minHeight?: string;
  isTransparentHeader?: boolean;
  onSuccessLogin?: () => void;
  /**
   * CTA 버튼 등이 있을 경우 footer의 padding-bottom을 별도로 지정
   */
  footerPaddingBottom?: number;
}

export default function Layout({
  children,
  size,
  backgroundColor,
  hasBottomNavigator,
  minHeight,
  isTransparentHeader,
  onSuccessLogin,
  footerPaddingBottom,
}: LayoutProps) {
  const { isMobile } = useCheckIsMobile();

  const { route } = useRouter();

  const setCurrentUserIdFromRecoil = useSetRecoilState(
    FORWARDING_AUTH_ATOMS.CURRENT_USER_ID
  );

  const setChatButtonUnreadCount = useSetAtom(
    COMMON_LAYOUT_ATOMS.CHAT_BUTTON_UNREAD_COUNT
  );

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const invitedTeamList = useRecoilValue(TEAM_ATOMS.INVITED_TEAM_LIST);

  const { checkLatestUsedTeamAccountAndSwitch } =
    useSwitchLatestUsedTeamAccount();

  const { data: userInfo } = USER_QUERY.useGetUserInfo({
    enabled: Boolean(loggedIn),
    onSuccess: (data) => {
      // 마지막으로 접속한 팀 id 를 체크해서 계정 스위칭
      checkLatestUsedTeamAccountAndSwitch(data.teamId, data.lastLoginTeamId);
    },
  });

  const { InvitedUserMismatchModal } = useAccessedByEmailInvitationModal({
    currentUserEmail: userInfo?.email,
  });

  useCheckInvitationTeamListWhenTokenRefreshed();

  useSetGTMByUserInfo();

  const { id: currentUserId } = userInfo || {};

  const isMyPage = route.includes("mypage");

  // 유저의 이메일을 emailLog를 전역 변수로 추가해달라는 마케팅목적의 요청
  if (typeof window !== "undefined") {
    (window as unknown as { emailLog: string | undefined }).emailLog =
      userInfo?.email;
  }

  /**
   * 채널톡 서버에 user정보를 셋팅
   **/
  useEffect(() => {
    // 채널톡은 KR에서만 사용
    if (APP_REGION !== "KR") return;

    setUserInfoToChannelIO({
      userInfo,
      onSuccess: (user) => {
        // 전에 접속한 사용자의 경우 미확인 메시지를 확인하여 업데이트해준다
        if (user?.alert) setChatButtonUnreadCount(user.alert);
      },
    });
  }, [setChatButtonUnreadCount, userInfo]);

  /**
   * Redux의 currentUserId가 바뀌면 recoil의 currentUserId도 수정
   * (인증 관련/로그아웃 기능이 Redux에 연결되어있어 임시로 이렇게 처리)
   */
  useEffect(() => {
    setCurrentUserIdFromRecoil(currentUserId);
  }, [currentUserId, setCurrentUserIdFromRecoil]);

  return (
    <>
      <Styled.container
        hasBottomNavigator={isMyPage ? false : hasBottomNavigator}
      >
        {isMyPage ? (
          <>
            <MyPageHeader />

            <Styled.myPageContentContainer isMobile={isMobile}>
              {children}
            </Styled.myPageContentContainer>
          </>
        ) : (
          <>
            <Header
              isTransparentBackground={isTransparentHeader}
              onSuccessLogin={onSuccessLogin}
            />

            <Styled.contentWrapper backgroundColor={backgroundColor}>
              <Styled.content
                size={size}
                minHeight={minHeight}
                isTransparentHeader={isTransparentHeader}
              >
                {children}
              </Styled.content>
            </Styled.contentWrapper>
          </>
        )}

        {!!invitedTeamList.length && <InvitationModal />}

        {InvitedUserMismatchModal}

        {/**
         * 쉽다 마이페이지는 네비게이션이 존재해 네비게이션 width만큼 left에서 떨어지게 작업*
         * TODO: 푸터 리뉴얼(풀반응형)이후에 <Footer /> 컴포넌트만 사용
         */}
        <Styled.footerContainerForMyPage isMyPage={isMyPage}>
          {isMyPage && !isMobile ? null : (
            <Footer
              paddingBottom={footerPaddingBottom}
              userInfo={userInfo}
              loggedIn={loggedIn}
            />
          )}
        </Styled.footerContainerForMyPage>

        <FloatingChatButton />
      </Styled.container>

      {Boolean(loggedIn) && <PopUpOnShipda />}
    </>
  );
}
