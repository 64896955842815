import { useMemo } from "react";

import { UserInfo } from "@sellernote/_shared/src/types/common/user";

import { useTranslation } from "../../../utils/i18n";

type Menu = {
  label: string;
  path?: string;
  width?: string;
  submenuList?: Submenu[];
};

type Submenu = {
  label: string;
  labelForFooter: string;
  desc?: React.ReactNode;
  badges?: string[];
  path: string;
  pathHash?: string;
  Icon?: React.ReactNode;
};

export type { Menu, Submenu };

/**
 * 메뉴 목록을 반환
 */
export default function useMenuList({
  userInfo,
  loggedIn,
}: {
  userInfo: UserInfo | undefined;
  loggedIn: boolean | undefined;
}) {
  const { t } = useTranslation(["common", "common-new"]);

  const menuList = useMemo(() => {
    // 멤버관리 - 운송관리 권한이 있는지 확인
    const hasBidAuthority =
      userInfo && userInfo.access.detail.transit === "write";

    const HOME_MENU: Menu = {
      label: "홈",
      width: "64px",
      submenuList: [
        {
          label: "홈",
          labelForFooter: "홈",
          path: "/",
        },
      ],
    };

    const COMPANY_MENU: Menu = {
      label: "회사소개",
      width: "80px",
      submenuList: [
        {
          label: "회사소개",
          labelForFooter: "회사소개",
          path: "/company",
        },
      ],
    };

    const SERVICE_FORWARDING_MENU: Menu = {
      label: "서비스(Forwarding)",
      width: "128px",
      submenuList: [
        ...(!loggedIn || hasBidAuthority
          ? [
              {
                label: "해상운송",
                labelForFooter: "해상운송",
                badges: ["FCL", "LCL"],
                desc: "전세계 → 한국, 해상운임 견적의뢰",
                path: "/forwarding/ocean",
              },
              {
                label: "항공운송",
                badges: ["AIR"],
                labelForFooter: "항공운송",
                desc: "전세계발 한국항 항공수입 견적의뢰",
                path: "/forwarding/air",
              },
            ]
          : []),
        {
          label: "스케줄 조회",
          badges: ["ALL"],
          labelForFooter: "스케줄 조회",
          desc: "해운/항공사별 스케줄 조회",
          path: "/forwarding/schedule",
        },
      ],
    };

    const SERVICE_FULFILLMENT_MENU: Menu = {
      label: "서비스(Fulfillment)",
      width: "116px",
      submenuList: [
        {
          label: `국내 출고`,
          badges: ["B2C", "B2B"],
          labelForFooter: `국내 출고`,
          desc: `스마트스토어, 쿠팡, 카페24 등 국내출고`,
          path: `/fulfillment/domestic`,
        },
        {
          label: `해외 출고`,
          badges: [`B2C`, `B2B`],
          labelForFooter: `해외 출고`,
          desc: `Shopify, Cafe24 Global 등 해외출고`,
          path: `/fulfillment/overseas`,
        },
        {
          label: `이벤트 출고`,
          badges: [`B2C`, `B2B`],
          labelForFooter: `이벤트 출고`,
          desc: `와디즈, 텀블벅, 홈쇼핑 등 단발성 대량출고`,
          path: `/fulfillment/event`,
        },
      ],
    };

    const SERVICE_TRADE_MENU: Menu = {
      label: "서비스(Trade Management)",
      width: "180px",
      submenuList: [
        {
          label: `대금결제(T/T)`,
          labelForFooter: `대금결제(T/T)`,
          desc: `은행 방문없이 온라인 무역대금 이체`,
          path: `/tt`,
        },
      ],
    };

    const TECH_MENU: Menu = {
      label: "기술",
      width: "64px",
      submenuList: [
        {
          label: `오픈 API`,
          labelForFooter: "오픈 API",
          desc: "발주, 운송 등 Open API 문서 제공",
          path: "https://developers.ship-da.com/",
        },
      ],
    };

    const SUPPORT_MENU: Menu = {
      label: "고객지원",
      width: "80px",
      submenuList: [
        {
          label: `${t("common-new:헤더_메뉴_고객지원_소분류_가이드")}(Guide)`,
          labelForFooter: t(
            "common-new:푸터_메뉴_프로모션_고객지원_소분류_가이드"
          ),
          desc: t("common-new:헤더_메뉴_고객지원_소분류_가이드_설명"),
          path: "/support/guide",
        },
        {
          label: t("common-new:헤더_메뉴_고객지원_소분류_공지사항"),
          labelForFooter: t(
            "common-new:푸터_메뉴_프로모션_고객지원_소분류_공지사항"
          ),
          desc: t("common-new:헤더_메뉴_고객지원_소분류_공지사항_설명"),
          path: "/support/notice",
        },
        {
          label: t("common-new:헤더_메뉴_고객지원_소분류_동영상"),
          labelForFooter: t(
            "common-new:푸터_메뉴_프로모션_고객지원_소분류_동영상"
          ),
          desc: t("common-new:헤더_메뉴_고객지원_소분류_동영상_설명"),
          path: "/support/onlinelecture",
        },
      ],
    };

    return [
      HOME_MENU,
      COMPANY_MENU,
      SERVICE_FORWARDING_MENU,
      SERVICE_FULFILLMENT_MENU,
      SERVICE_TRADE_MENU,
      TECH_MENU,
      SUPPORT_MENU,
    ];
  }, [t, userInfo]);

  return menuList;
}
